import $ from 'jquery';


import './../../scss/blocks/block-experiences.scss';

$(document).ready(function() {
  startCounter()
  $(window).on('scroll resize',function(){
    if ($('.block-experiences-section-wrap').isInViewport()) {
      startCounter()
      if ($(window).width() > 992) {
        var parallaxElement = $('.block-experiences-section-wrap .gallery-wrap'),
        parallaxQuantity = parallaxElement.length;
        window.requestAnimationFrame(function() {
          for (var i = 0; i < parallaxQuantity; i++) {
            var currentElement = parallaxElement.eq(i),
              windowTop = $(window).scrollTop(),
              elementTop = currentElement.offset().top,
              elementHeight = currentElement.height(),
              viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
              scrolled = windowTop - elementTop + viewPortHeight;
            currentElement.css({
              transform: "translate3d(0," + scrolled * -0.20 + "px, 0)"
            });
          }
        });  
      }
      
    }
  })

});


$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

let counterdone = false
function startCounter() {
  let scrollY = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
  let divPos = document.querySelector('.hodnotenia-wrap').offsetTop;
  //console.log(scrollY, divPos)
  if ($('.block-experiences-section-wrap .hodnotenia-wrap').isInViewport() && counterdone == false) {
    counterdone = true
    //console.log('t')
    $(window).off("scroll", startCounter);

    $('.hodnotenia-item .title').each(function() {
      let $this = $(this);
      let timeout = 500;
      setTimeout( function(){ 
        $({
          Counter: 0
        }).animate({
          Counter: $this.attr('data-number')*1
        }, {
          duration: 2000,
          easing: 'swing',
          step: function() {
            $this.text(commaSeparateNumber(Math.floor(this.Counter)));
          },
          complete: function() {
            $this.text(commaSeparateNumber(this.Counter)+'+');
            //alert('finished');
          }
        });  
      }  , timeout );
      timeout = timeout+100;
      
    });

    function commaSeparateNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
}